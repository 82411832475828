<template>
  <div class="public-page">
    <auth-navbar></auth-navbar>
    <div class="wrapper wrapper-full-page">
      <div class="full-page section-image">
        <div class="content wide-page">
          <transition mode="out-in" name="fade">
            <slot />
          </transition>
        </div>
        <content-footer></content-footer>
        <div
          :style="{ backgroundImage: `url(${backgroundUrl})` }"
          class="full-page-background"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthNavbar from "~/components/auth/AuthNavbar";
import ContentFooter from "~/components/general/Footer.vue";
import backgroundUrl from "~/assets/img/background/toolsquare.jpg";

export default {
  components: {
    AuthNavbar,
    ContentFooter,
  },
  data() {
    return {
      backgroundUrl,
    };
  },
};
</script>
